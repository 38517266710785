import Api from "@/services/Api";

export default {
  //Takes groupId and refPackage as parameters
  updateGroupPackage(params) {
    return Api().post("updateGroupPackage", params);
  },

  removeGroupSubscription(params) {
    return Api().post("removeGroupSubscription", params);
  },

  applyGroupSubscription(params) {
    return Api().post("applyGroupSubscription", params);
  },

  //Takes in an object which contain a reference to a service (refService) and a reference to a Group (refGroup)
  removeServiceFromGroup(params) {
    return Api().post("removeServiceFromGroup", params);
  },

  //Takes in an object which contain a reference to a service (refService) and a reference to a Group (refGroup)
  addServiceToGroup(params) {
    return Api().post("addServiceToGroup", params);
  },
};
