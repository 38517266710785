<template>
  <v-layout column>
    <v-container class="px-6 pt-0" v-if="true">
      <div class="align-center text-center mt-0">
        <v-card elevation="6" max-width="1200" style="margin: auto">
          <v-toolbar color="blue darken-3" dark flat dense>
            <v-toolbar-title>{{
              $t("tables.viewGroupServices")
            }}</v-toolbar-title>
          </v-toolbar>
        </v-card>

        <v-card elevation="6" max-width="1200" style="margin: auto">
          <v-container class="align-center text-center mx-0 sm">
            <v-row>
              <v-col md="3" offset="0">
                <v-flex class="md12 ml-4">
                  <v-container fluid>
                    <v-select
                      class=""
                      v-model="groupName"
                      :items="groupNames"
                      :search-input.sync="search"
                      hide-selected
                      :hint="$t('global.maximum1Value')"
                      :label="$t('global.groupName')"
                      persistent-hint
                      outlined
                      dense
                      small-chips
                    >
                    </v-select>
                  </v-container>
                </v-flex>
              </v-col>
              <v-col md="2" offset="0">
                <v-flex class="md12">
                  <v-btn
                    elevation="2"
                    class="ml2 mt-5 mr-2"
                    @click="getGroupServices()"
                  >
                    {{ $t("button.view") }}
                  </v-btn>
                </v-flex>

                <div class="red--text" v-html="viewError" />
                <div class="green--text" v-html="viewSuccess" />
              </v-col>

              <!--ENGLISH-->
              <v-col col="6" offset="0" v-if="$i18n.locale == 'en'">
                <v-flex class="md12">
                  <v-data-table
                    :headers="headers"
                    :items="groupServices"
                    :items-per-page="5"
                    class="elevation-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.cards8`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.cards8">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.cards8">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.cards12`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.cards12">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.cards12">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.limitedPortal`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.limitedPortal">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.limitedPortal">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.advPortal`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.advPortal">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.advPortal">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.csvExport`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.csvExport">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.csvExport">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.analytics`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.analytics">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.analytics">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.control`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.control">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.control">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.extIntegration`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.extIntegration">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.extIntegration">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.notification`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.notification">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.notification">close</v-icon>
                      </td>
                    </template>

                  </v-data-table>
                </v-flex>
              </v-col>

              <!--PORTUGUES-->
              <v-col col="6" offset="0" v-if="$i18n.locale == 'pt'">
                <v-flex class="md12">
                  <v-data-table
                    :headers="headersPt"
                    :items="groupServices"
                    :items-per-page="5"
                    class="elevation-1"
                    hide-default-footer
                  >
                  <template v-slot:[`item.cards8`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.cards8">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.cards8">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.cards12`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.cards12">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.cards12">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.limitedPortal`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.limitedPortal">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.limitedPortal">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.advPortal`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.advPortal">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.advPortal">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.csvExport`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.csvExport">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.csvExport">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.analytics`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.analytics">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.analytics">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.control`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.control">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.control">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.extIntegration`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.extIntegration">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.extIntegration">close</v-icon>
                      </td>
                    </template>
                    <template v-slot:[`item.notification`]="{ item }">
                      <td class="d-flex align-center">
                        <v-icon color="green darken-2" v-if="item.notification">done</v-icon>
                        <v-icon color="red darken-2" v-if="!item.notification">close</v-icon>
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-col>
            </v-row>
          </v-container>

          <!--MANAGE GROUP PACKAGE-->

          <v-toolbar flat dense class="blue darken-3 mt-10" dark>
            <v-toolbar-title>{{
              $t("tables.manageGroupPackage")
            }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4 mb-10">
            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="groupName1"
                  :items="groupNames"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.groupName')"
                  outlined
                  persistent-hint
                  small-chips
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-combobox
                  class=""
                  v-model="selectedPackage"
                  :items="appPackages"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.packages')"
                  outlined
                  multiple
                  persistent-hint
                  small-chips
                >
                </v-combobox>
              </v-container>
            </v-flex>

            <v-btn
              elevation="2"
              class="ml-16 mt-5 mr-6"
              @click="updateGroupPackage"
            >
              {{ $t("button.change") }}
            </v-btn>

            <div class="red--text ml-16" v-html="changeError" />
            <div class="green--text ml-16" v-html="changeSuccess" />
          </div>

          <!--MANAGE GROUP SUBSCRIPTIONS-->

          <v-toolbar flat dense class="blue darken-3 mt-10" dark>
            <v-toolbar-title>{{
              $t("tables.manageGroupSubscriptions")
            }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4 mb-10">
            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="groupName2"
                  :items="groupNames"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.groupName')"
                  outlined
                  persistent-hint
                  small-chips
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-combobox
                  class=""
                  v-model="selectedSubscription"
                  :items="subscriptions"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.subscriptions')"
                  outlined
                  multiple
                  persistent-hint
                  small-chips
                >
                </v-combobox>
              </v-container>
            </v-flex>

            <v-btn
              elevation="2"
              class="ml-16 mt-5 mr-6"
              @click="updateGroupSubscription"
            >
              {{ $t("button.change") }}
            </v-btn>

            <div class="red--text ml-16" v-html="changeError1" />
            <div class="green--text ml-16" v-html="changeSuccess1" />
          </div>

          <!--MANAGE GROUP EXTRAS-->

          <v-toolbar flat dense class="blue darken-3 mt-10" dark>
            <v-toolbar-title>{{
              $t("tables.manageGroupExtras")
            }}</v-toolbar-title>
          </v-toolbar>

          <div class="d-flex mt-4 mb-10">
            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-select
                  class=""
                  v-model="groupName3"
                  :items="groupNames"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.groupName')"
                  outlined
                  persistent-hint
                  small-chips
                >
                </v-select>
              </v-container>
            </v-flex>

            <v-flex class="md3 ml-4">
              <v-container fluid>
                <v-combobox
                  class=""
                  v-model="selectedService"
                  :items="appServices"
                  :search-input.sync="search"
                  hide-selected
                  :hint="$t('global.maximum1Value')"
                  :label="$t('global.services')"
                  outlined
                  multiple
                  persistent-hint
                  small-chips
                >
                </v-combobox>
              </v-container>
            </v-flex>

            <v-btn
              elevation="2"
              class="ml-16 mt-5 mr-6"
              @click="addServiceToGroup"
            >
              {{ $t("button.add") }}
            </v-btn>

            <v-btn
              elevation="2"
              class="ml-16 mt-5 mr-6"
              @click="removeServiceFromGroup"
            >
              {{ $t("button.remove") }}
            </v-btn>

            <div class="red--text ml-16" v-html="changeError2" />
            <div class="green--text ml-16" v-html="changeSuccess2" />
          </div>
        </v-card>
      </div>
    </v-container>
  </v-layout>
</template>

<script>
import GroupService from "@/services/GroupService";
import ServicesService from "@/services/ServicesService";
import HelperMethods from "@/utilities/HelperMethods";

export default {
  data() {
    return {
      group: {},
      groupServices: [],
      groups: [],
      groupNames: [],
      services: [],
      headers: [
        { text: "Package", value: "refPackage" },
        { text: "Subscription", value: "subscription" },
        { text: "8 Cards", value: "cards8" },
        { text: "12 Cards", value: "cards12" },
        { text: "Limited Portal", value: "limitedPortal" },
        { text: "Advanced Portal", value: "advPortal" },
        { text: ".csv Export", value: "csvExport" },
        { text: "Analytics", value: "analytics" },
        { text: "Control", value: "control" },
        { text: "External Integration", value: "extIntegration" },
        { text: "Notification", value: "notification" },
        { text: "Sample Rate", value: "sampleRate" },
      ],
      headersPt: [
        { text: "Pacote", value: "refPackage" },
        { text: "Subscrição", value: "subscription" },
        { text: "8 Cartas", value: "cards8" },
        { text: "12 Cartas", value: "cards12" },
        { text: "Portal Limitado", value: "limitedPortal" },
        { text: "Portal Avançado", value: "advPortal" },
        { text: "Exporte para .csv", value: "csvExport" },
        { text: "Análise Dados", value: "analytics" },
        { text: "Controlo", value: "control" },
        { text: "Integração Externa", value: "extIntegration" },
        { text: "Notificações", value: "notification" },
        { text: "Impulso", value: "sampleRate" },
      ],
      appServices: [
        "8 Cards",
        "12 Cards",
        "Limited Portal",
        "Adv. Portal",
        ".csv Export",
        "Ext. Integration",
        "Analytics",
        "Control",
        "Notifications",
        "sr 20",
        "sr 10",
        "sr 5",
      ],
      appPackages: [
        "INDIVIDUAL",
        "MULTIPLE",
        "MINIUS",
        "LIMIA",
        "NERBIS",
        "TURIA",
      ],
      subscriptions: ["No Subscription", "Fee Basic"],
      selectedSubscription: "",
      selectedService: "",
      selectedPackage: "",
      groupName: null,
      groupName1: null,
      groupName2: null,
      groupName3: null,
      search: null,
      viewError: null,
      viewSuccess: null,
      changeError: null,
      changeSuccess: null,
      changeError1: null,
      changeSuccess1: null,
      changeError2: null,
      changeSuccess2: null,
    };
  },

  methods: {
    async getGroups() {
      try {
        let response = await GroupService.getAllGroups();

        this.groups = response.data;

        for (let i = 0; i < this.groups.length; i++) {
          this.groupNames.push(this.groups[i].name);
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getGroupServices() {
      if (this.groupName == null) {
        this.error = "Please select a group first.";
        return;
      }
      this.error = null;
      this.groupServices = [];
      this.services = [];
      let group = HelperMethods.getGroupObjByName(this.groups, this.groupName);

      let groupId = group.id;
      let groupRefPackage = group.refPackage;
      let options = {
        id: groupId,
        refPackage: groupRefPackage,
      };

      try {
        let response = await GroupService.getGroupServicesInfo(options);

        for (let i = 0; i < response.data.extras.length; i++) {
          this.services.push(response.data.extras[i].refService);
        }

        for (let i = 0; i < response.data.package.length; i++) {
          this.services.push(response.data.package[i].refService);
        }

        let packageName = HelperMethods.namePackage(
          response.data.package[0].refPackage
        );

        this.services.sort((a, b) => a - b);

        this.groupServices.push(
          HelperMethods.buildServicesObject(this.services)
        );

        if (this.groupServices[0].feeBasic == true) {
          this.groupServices[0].subscription = "Fee Basic";
        } else if (this.groupServices[0].feeBasic == false) {
          this.groupServices[0].subscription = "No Subscription";
        }
        this.groupServices[0].refPackage = packageName;
        this.groupServices[0].sampleRate = response.data.sr;
      } catch (err) {
        console.log(err);
      }
    },

    async updateGroupPackage() {
      let group = HelperMethods.getGroupObjByName(this.groups, this.groupName1);
      let groupId = group.id;
      let refPackage = HelperMethods.getPackageReference(
        this.selectedPackage[0]
      );

      try {
        let response = await ServicesService.updateGroupPackage({
          group: groupId,
          package: refPackage,
        });

        this.getGroups();
        this.changeError = null;
        this.changeSuccess = response.data.message;
      } catch (err) {
        this.changeSuccess = null;
        this.changeError =
          "An error occurred while trying to update the group package.";
        console.log(err);
      }
    },

    async updateGroupSubscription() {
      let group = HelperMethods.getGroupObjByName(this.groups, this.groupName2);
      let groupId = group.id;
      let serviceId = HelperMethods.getServiceRefByName(
        this.selectedSubscription[0]
      );
      console.log("serviceId: ", serviceId);

      if (serviceId === 8) {
        try {
          let response = await ServicesService.applyGroupSubscription({
            refGroup: groupId,
            refService: 99,
          });
          this.changeSuccess1 = response.data.message;
          this.changeError1 = null;
        } catch (err) {
          this.changeError1 = err.response.data.message;
          this.changeSuccess1 = null;
        }
      } else if (serviceId === 14) {
        try {
          let response = await ServicesService.removeGroupSubscription({
            refGroup: groupId,
            refService: 8,
          });
          this.changeSuccess1 = response.data.message;
          this.changeError1 = null;
        } catch (err) {
          this.changeError1 = err.response.data.message;
          this.changeSuccess1 = null;
        }
      }
    },

    async addServiceToGroup() {
      let group = HelperMethods.getGroupObjByName(this.groups, this.groupName3);
      let groupId = group.id;
      console.log("groupId: ", groupId)

      let serviceId = HelperMethods.getServiceRefByName(
        this.selectedService[0]
      );
      console.log("serviceId: ", serviceId)
      try {
        let response = await ServicesService.addServiceToGroup({
          refGroup: groupId,
          refService: serviceId,
        });
        this.changeSuccess2 = response.data.message;
        this.changeError2 = null;
      } catch (err) {
        this.changeError2 = err.response.data.message;
        this.changeSuccess2 = null;
      }
    },

    async removeServiceFromGroup() {
      let group = HelperMethods.getGroupObjByName(this.groups, this.groupName3);
      let groupId = group.id;
      let serviceId = HelperMethods.getServiceRefByName(
        this.selectedService[0]
      );

      try {
        let response = await ServicesService.removeServiceFromGroup({
          refGroup: groupId,
          refService: serviceId,
        });
        this.changeSuccess2 = response.data.message;
        this.changeError2 = null;
      } catch (err) {
        this.changeError2 = err.response.data.message;
        this.changeSuccess2 = null;
      }
    },
  },

  mounted() {
    this.getGroups();
  },

  watch: {
    groupName(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.groupName.pop());
      }
    },
    groupName1(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.groupName1.pop());
      }
    },
    groupName2(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.groupName2.pop());
      }
    },
    groupName3(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.groupName3.pop());
      }
    },
    selectedPackage(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.selectedPackage.pop());
      }
    },
    selectedService(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.selectedService.pop());
      }
    },
    selectedSubscription(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.selectedSubscription.pop());
      }
    },
    groupServices(val) {
      // console.log("groupServices changed:", this.groupServices);
      this.groupServices = val;
    },
  },
};
</script>
